import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';

const ContactForm = () => {
  const formRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = (formData) => {
    const newErrors = {};
    if (formData['firstname'] === '') {
      newErrors.firstname = 'Please fill out this field.';
    }

    if (formData['lastname'] === '') {
      newErrors.lastname = 'Please fill out this field.';
    }

    if (formData['companyname'] === '') {
      newErrors.companyname = 'Please fill out this field.';
    }

    if (formData['query'] === '') {
      newErrors.query = 'Please select an item.';
    }

    if (formData['message'] === '') {
      newErrors.message = 'Please fill out this field.';
    }

    const email = formData['email'];
    if (email === '') {
      newErrors.email = 'Please fill out this field.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      newErrors.email = 'Invalid email address';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formD = new FormData(e.target);
    const obj = {};
    formD.forEach((value, key) => {
      obj[key] = value;
    });
    if (!validateForm(obj)) {
      return;
    }
    console.log(obj, 'my obj');
    try {
      setSubmitting(true);

      const response = await axios.post('https://news.securiwiser.com/', obj, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        if (typeof window !== 'undefined') {
          window.location = '/thank-you/';
        }
        formRef.current.reset();
      } else {
        alert('Message Failed. Something went wrong.');
      }
    } catch (error) {
      console.error(error);
      alert('Message Failed. Something went wrong.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    // save the files with command s
    <>
      <h1 className='form-heading sw-heading-1'>Contact our team</h1>
      <form method='POST' name='contact' onSubmit={handleSubmit} ref={formRef}>
        <input type='hidden' name='contact' value='contact' />
        <div className='form-group'>
          <div className='input-group'>
            <label htmlFor='firstname'>
              First name<span>*</span>
            </label>
            <input className='input-0' type='text' name='firstname' />
            <p className='error'>{errors.firstname}</p>
          </div>
          <div className='input-group'>
            <label htmlFor='lastname'>
              Last name<span>*</span>
            </label>
            <input className='input-0' type='text' name='lastname' />
            <p className='error'>{errors.lastname}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='input-group'>
            <label htmlFor='email'>
              Work email<span>*</span>
            </label>
            <input className='input-0' type='email' name='email' />
            <p className='error'>{errors.email}</p>
          </div>
          <div className='input-group'>
            <label htmlFor='companyname'>
              School name<span>*</span>
            </label>
            <input className='input-0' type='text' name='companyname' />
            <p className='error'>{errors.companyname}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='input-group'>
            <label htmlFor='phone'>Phone number</label>
            <input type='phone' name='phone' />
          </div>
          <div className='input-group'>
            <label htmlFor='query'>
              Type of query<span>*</span>
            </label>
            <select name='query'>
              <option value=''>Please select</option>
              <option value='account'>Account</option>
              <option value='sales'>Sales</option>
              <option value='support'>Support</option>
              <option value='press'>Press</option>
              <option value='other'>Other</option>
            </select>
            <p className='error'>{errors.query}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='input-group'>
            <label htmlFor='message'>
              How can our team help you<span>*</span>
            </label>
            <textarea
              type='text'
              name='message'
              placeholder='Tell us more about your team and what you’d like to manage with securiwiser.com'
            />
            <p className='error'>{errors.message}</p>
            <p className='privacy-policy'>
              By clicking submit, I acknowledge receipt of the securiwiser.com{' '}
              <Link className='sw-link' to='/privacy-policy/' target='_blank'>
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
        {!submitting ? (
          <button
            className='sw-primary-btn sw-nav-links sw-font-weight-md fom-btn'
            type='submit'>
            Submit
          </button>
        ) : (
          <button
            className='sw-primary-btn sw-nav-links sw-font-weight-md fom-btn'
            type='button'
            disabled>
            Submitting...
          </button>
        )}
      </form>
    </>
  );
};

export default ContactForm;
